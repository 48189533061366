import Vue from 'vue';
import App from './App.vue';
import Vuetify from "vuetify";
import 'vuetify/dist/vuetify.min.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {routes} from './routes/index';
import VueRouter from 'vue-router';
import store from './store';
import CKEditor from '@ckeditor/ckeditor5-vue';
import CppRestClient from "cpp-js-util/CppRestClient";
import Feedback from "cpp-js-util/Feedback";
import Filter from "cpp-js-util/Filter";
import TokenManager from "cpp-js-util/TokenManager";
import Logger from "cpp-js-util/logger";
import Validation from "cpp-js-util/Validation";
import VueGtag from "vue-gtag";
import Loader from "cpp-js-util/Loader";
import "vue-pdf-app/dist/icons/main.css";
import VuePdfApp from "vue-pdf-app";
import en from "@/util/local-language/en";
import bn from "@/util/local-language/bn";
import VueI18n from 'vue-i18n';
import VueSocialSharing from 'vue-social-sharing';
import LoadScript from 'vue-plugin-load-script';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import VueTour from 'vue-tour';
import EventBus from './event-bus';

Vue.use(LoadScript);

Vue.use(VueSocialSharing);
Vue.use(Loader);
Vue.component("vue-pdf-app", VuePdfApp);
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

Vue.use(Logger, {
    enableLogging: process.env.VUE_APP_LOGGER_STATE === 'true'
})
Vue.use(Filter)
Vue.use(TokenManager)

TokenManager.onTokenRefreshFailure(() => {
    Feedback.hideLoading()
    document.title = process.env.VUE_APP_PAGE_TITLE
    store.commit('logoutUser')
    store.commit('clearPageState');
    router.push({name: 'sign-in'});
})

Vue.config.productionTip = false

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#e2136e',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
    },
});
const cppClient = new CppRestClient(process.env.VUE_APP_MERCHANT_PORTAL_BG_URL, store)
const cppClientExternalServices = new CppRestClient(process.env.VUE_APP_EXTERNAL_SERVICES_MICROSERVICE_URL, store)
const cppClientProkashServices = new CppRestClient(process.env.VUE_APP_PROKASH_MICROSERVICE_URL, store)

Vue.prototype.$cppClient = cppClient;
Vue.prototype.$cppClientExternalServices = cppClientExternalServices;
Vue.prototype.$cppClientProkashServices = cppClientProkashServices;
Vue.prototype.$feedback = Feedback;
Vue.prototype.$validation = Validation;
Vue.prototype.$eventBus = EventBus;

Vue.use(CKEditor);
Vue.use(DatePicker);
Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.use(VueI18n);

const currentLang = localStorage.getItem("lang");
const i18n = new VueI18n({
    locale: currentLang ? currentLang : process.env.VUE_APP_I18N_LOCAL,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCAL,
    messages: {
        বাংলা: bn,
        eng: en,
    }
});

const fpPromise = FingerprintJS.load();

(async () => {
    const fp = await fpPromise
    const result = await fp.get()
    const visitorId = localStorage.getItem("visitorId");
    if (!visitorId) localStorage.setItem("visitorId", result.visitorId + new Date().getTime());
    console.log("guestId", localStorage.getItem("visitorId"));
})()

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});


Vue.use(VueGtag, {
    includes: [{id: process.env.VUE_APP_GA4_ANALYTICS_TAG_ID}],
    config: {id: process.env.VUE_APP_ANALYTICS_TAG_ID},
    appName: 'CPP Merchant Portal'
}, router);

router.beforeEach((to, from, next) => {
    let isAuthenticated = store.getters.isAuthenticated;

    // These pages are accessible regardless of the user is logged in or not. However, the
    // restrictions of the filter "notAllowedRoutesWithAuth" may apply.
    let allowedRoutesWithoutAuth = to.name === 'confirm-sign-up' || to.name === 'forgot-password' || to.name === 'set-password'
        || to.name === 'reset-password' || to.name === 'error' || to.name === 'statement'
        || to.name === 'sign-up' || to.name === 'landing' || to.name === 'earn-with-dashboard'
        || to.name === 'email-verification-success' || to.name === 'blog-list' || to.name === 'blog-details'
        || to.name === "refer" || to.name === "refer-form"|| to.name === "referralreport" || to.name === "str-sar-report"
        || to.name === "privileged-onboarding";

    // These pages are ONLY accessible when the user is NOT logged in
    let notAllowedRoutesWithAuth = to.name === 'sign-in' || to.name === 'confirm-sign-up'
        || to.name === 'forgot-password' || to.name === 'reset-password' || to.name === 'set-password'
        || to.name === 'sign-up' || to.name === 'earn-with-dashboard';

    if (!isAuthenticated && allowedRoutesWithoutAuth) {
        next()
    } else if (!isAuthenticated && to.name !== 'sign-in') {
        next({name: 'landing'})
    } else {
        if (isAuthenticated && notAllowedRoutesWithAuth) {
            next({name: 'dashboard'})
        } else {
            next()
        }
    }
})

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app')
