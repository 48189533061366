const bn = {
    menu: {
        home: 'হোম'
    },
    setup: {
        title: 'বিকাশ বিজনেস ড্যাশবোর্ডে',
        title_slot: 'আপনাকে স্বাগতম',
        sub_title: 'বিকাশ বিজনেস ড্যাশবোর্ড আপনাকে পেমেন্ট লিঙ্ক তৈরি করতে, লেনদেন ট্র্যাক করতে এবং ব্যবসায়ী তথ্য দেখতে দেয় যাতে আপনি আপনার ব্যবসা বাড়ানোর উপর বেশি মনোযোগ দিতে পারেন',
        prerequisite: {
            title: 'আপনার কি কি প্রয়োজন?',
            wallet: 'বিকাশ মার্চেন্ট / পার্সোনাল রিটেইল একাউন্ট',
            email: 'সক্রিয় ইমেইল অ্যাকাউন্ট',
        },
        wallet_entry_form: {
            insert_wallet: 'আপনার বিকাশ মার্চেন্ট একাউন্ট নাম্বার দিন',
            terms_conditions: 'আপনি কি আমাদের {conditions} সম্মতি দিচ্ছেন?',
            terms_conditions_slot: 'শর্তাবলীতে',
            must_select: 'এগিয়ে চলার জন্য দয়া করে শর্তাদি মেনে নিন।',
            have_account: 'ইতিমধ্যে অ্যাকাউন্ট আছে?',
            sign_in: 'সাইন ইন করুন',
            wallet_mandatory: 'ওয়ালেট নম্বর প্রবেশ বাধ্যতামূলক',
            wallet_length: 'ওয়ালেট সংখ্যা 11 ডিজিটের থাকতে হবে',
            wallet_valid: 'একটি বৈধ ওয়ালেট নম্বর দিন যা 01 দিয়ে শুরু হবে',
            must_agree: 'এগিয়ে চলার জন্য দয়া করে শর্তাদি মেনে নিন।',
        },
        otp_entry_form: {
            insert_otp: 'আপনাকে পাঠানো OTP দিন ',
            resend: 'পুণরায় OTP পাঠান',
            otp_blank: "ওটিপি বাধ্যতামূলক",
            otp_length: 'ওটিপি অবশ্যই 6 ডিজিটের হতে হবে',
        },
        email_entry_form: {
            insert_mail: 'ইমেইল অ্যাড্রেস দিন',
        },
        confirm_email_entry_form: {
            insert_mail: 'পুনরায় ইমেইল অ্যাড্রেস দিন',
        },
        verify_email: {
            verify_title: 'আপনার ইমেইল অ্যাড্রেস যাচাই করুন',
            sent: 'আপনার ইমেইল - {email-address} এ একটি ভেরিফিকেশন লিংক পাঠানো হয়েছে',
            use_link: 'লিংক থেকে ইমেইল যাচাই করুন।',
            resend: 'আবার ইমেল পাঠান',
            success_title_1: 'আপনাকে ধন্যবাদ রেজিস্ট্রেশন করার জন্য',
            success_title_2: 'বিকাশ বিসনেস ড্যাশবোর্ডে',
            success_msg_1: 'বিকাশ বিজনেস ড্যাশবোর্ডে সাইন ইন করার জন্য আপনার ইমেইল অ্যাড্রেসে একটি পাসওয়ার্ড সেটআপ লিংক পাবেন। প্রথমবার সাইন ইন করার সময় আপনার পাসওয়ার্ডটি সেট করে নিতে হবে।',
            success_msg_2: 'ইমেইল না পেলে 24 ঘন্টা পর আবার চেষ্টা করুন',
        },
        email_length: "কমপক্ষে 4 টি অক্ষর লিখুন।",
        email_valid: 'ইমেল বৈধ হতে হবে',
        email_mismatch: 'আপনার ইমেইল ভুল দিয়েছেন',
        home_btn: 'হোমপেজে ফিরে যান',
        proceed_btn: 'এগিয়ে যান',
        coming_soon: {
            title: 'শীঘ্রই আসছে!',
            sub: 'বর্তমানে ‘অনলাইনে বিকাশ পেমেন্ট গ্রহণের জন্য রেজিস্ট্রেশন’ ফিচারটির কাজ চলছে। খুব শীঘ্রই আপনারা অনলাইনে পেমেন্ট গ্রহণের জন্য রেজিস্ট্রেশন করতে পারবেন। এই মূহুর্তে রেজিস্ট্রেশন আবেদন করার জন্য নীচের লিংকে ক্লিক করুন।',
            btn: {
                register: 'রেজিস্ট্রেশনের আবেদন করুন',
                home: 'হোম-এ ফিরে যান'
            }
        },
        copyright: 'কপিরাইট © বিকাশ ২০২১ লিমিটেড'
    },
    earnWithBkash: {
        title: 'বিকাশ দিয়ে অনলাইনে আয় করুন',
        advertisementText: 'পেমেন্ট গ্রহণ, টাকা প্রদানের মাধ্যমে ব্যবসা করুন সাচ্ছন্দে',
        descriptionText: 'বিকাশ বিসনেস ড্যাশবোর্ডে বিনামূল্যে রেজিস্টার করুন আপনার বিকাশ মার্চেন্ট একাউন্ট দিয়ে',
        signup: 'সাইন আপ',
        learnMore: 'আরো জানুন'
    },
    referral: {
        title: "বিজনেস ড্যাশবোর্ডে রেফার করলেই",
        subTitle: "আপনার রেফারেল লিংক এর মাধ্যমে আপনার পরিচিত কেও ড্যাশবোর্ডে  সাইন আপ করে পেমেন্ট নিলেই পেয়ে যাবেন ১০০ টাকা",
        subTitle2: "আপনার রেফার করা পরিচিত কেও ড্যাশবোর্ডে সাইন আপ করে পেমেন্ট নিলেই পেয়ে যাবেন ১০০ টাকা",
        earnAmount: "১০০ টাকা",
        bonus: "বোনাস*",
        referralLink: "রেফারাল লিংক",
        copyLink: "কপি করুন",
        shareLink: "আপনার রেফারাল লিংক শেয়ার করুন",
        referPersonally: "পরিচিত ব্যক্তির নম্বর লিখে রেফার করুন",
        referrerInputTitle: "কাস্টমার/মার্চেন্ট/পার্সোনাল রিটেইল একাউন্ট",
        referrerInputLabel: "আপনার বিকাশ নম্বর দিন",
        referredInputTitle: "বিকাশ মার্চেন্ট/নন-বিকাশ নম্বর",
        referredInputLabel: "রেফার ব্যক্তির নম্বর লিখুন",
        referredEmailCheckboxLabel: "আপনার বন্ধুকে ইমেল পাঠান",
        referredEmailInputLabel: "রেফার ব্যক্তির ইমেইল লিখুন",
        referButtonLabel: "রেফার করুন",
        checkReferralLinkPart1: "আপনার রেফারেল চেক করুন",
        checkReferralLinkPart2: "এখানে",
        referSuccessMessage: "আপনার মার্চেন্ট রেফারেল {referredWallet} সফল হয়েছে৷ আরও মার্চেন্ট রেফার করুন এবং পুরস্কার জিতুন!",
        descriptionTitle: "রেফার করে ৳১০০ জিতে নিন।",
        description: "জেতার জন্য, আপনার বন্ধুকে সাইন আপ করতে হবে এবং পেমেন্ট লিঙ্ক ব্যবহার করে পেমেন্ট নিতে হবে।",
        conditions: "শর্ত প্রযোজ্য",
        learnMore: "বিকাশ বিজনেস ড্যাশবোর্ড  সম্পর্কে আরো জানুন ",
        learnMoreHere: "[এখানে]"
    },
    referralReport: {
        title: "আপনার রেফারেল সম্পর্কে জানুন",
        subTitle: "আপনার রেফারেল সম্পর্তে জানতে আপনার বিকাশ নম্বর ভেরিফাই করে আপনার প্রদত্ত ইমেইলে তথ্য পাঠানো হবে",
        referrerInputTitle: "কাস্টমার/মার্চেন্ট/পার্সোনাল রিটেইল একাউন্ট",
        referrerInputLabel: "আপনার বিকাশ নম্বর দিন",
        sendOtpButtonLabel: "OTP পাঠান",
        otpInputTitle: "OTP",
        otpInputLabel: "OTP লিখুন",
        emailInputTitle: "ইমেইল (রিপোর্ট পেতে)",
        emailInputLabel: "আপনার ইমেল লিখুন",
        sendEmailButtonLabel: "ইমেইল পাঠান",
        resendOtpText: "আপনি {0} সেকেন্ডের মধ্যে আরেকটি OTP অনুরোধ করতে পারেন।",
        otpSent: "আপনাকে {referrerWallet}-এ একটি OTP পাঠানো হয়েছে",
        reportSent: "আপনি {referrerEmail} এ আপনার রিপোর্ট পাবেন৷ আরও মার্চেন্ট রেফার করুন এবং পুরস্কার জিতুন!",
        wallet_entry_form: {
            wallet_mandatory: 'ওয়ালেট নম্বর প্রবেশ বাধ্যতামূলক',
            wallet_length: 'ওয়ালেট সংখ্যা 11 ডিজিটের থাকতে হবে',
            wallet_valid: 'একটি বৈধ ওয়ালেট নম্বর দিন যা 01 দিয়ে শুরু হবে',
        },
        email_length: "কমপক্ষে 4 টি অক্ষর লিখুন।",
        email_valid: 'ইমেল বৈধ হতে হবে',
        otp_entry_form: {
            otp_blank: "ওটিপি বাধ্যতামূলক",
            otp_length: 'ওটিপি অবশ্যই 6 ডিজিটের হতে হবে',
        }
    },
    landing: {
        registerInfo: {
            businessDashboard: 'বিজনেস ড্যাশবোর্ড',
            createStore: 'সহজে ওয়েবসাইট তৈরী করুন এবং অনলাইনে পেমেন্ট নিন মিনিটেই',
            easySetup: 'সহজে কোডিং ছাড়াই ব্যবহার করুন, ব্যবসা করুন সামাজিক মাধ্যমে',
            getStarted: 'ফ্রি সাইন আপ',
            getBonus: 'সাইন আপ করে জিতে নিন ৳১০০',
            noAccount: 'মার্চেন্ট একাউন্ট নেই ? সমস্যা নেই',
            register: 'রেজিস্ট্রেশন করুন'
        },
        features: {
            paymentExperience: {
                t1: 'ঝামেলাহীন পেমেন্ট সুবিধা',
                t2: ' গ্রাহক',
                t3: ' ও',
                t4: ' মার্চেন্ট',
                t5: ' এর জন্য'
            },
            pageManagement: {
                title: 'পেমেন্ট ম্যানেজমেন্ট',
                content: 'আপনার অনলাইন ব্যবসাকে ঝামেলামুক্ত করতে পেমেন্ট লিঙ্ক তৈরি করুন এবং শেয়ার করুন '
            },
            report: {
                title: 'লেনদেন এবং রিপোর্ট',
                content: 'সহজে আপনার রিপোর্ট দেখুন, জানুন আপনার ব্যবসা এবং কাস্টমার সম্পর্কে '
            },
            store: {
                title: 'অনলাইন শপ',
                content: 'নো-কোড অনলাইন স্টোর তৈরি করুন আপনার পণ্য এবং আপনার ব্যবসার বিবরণ দিয়ে'
            }
        },
        chatDemo: {
            title: 'সরাসরি বিক্রি শুরু করুন',
            content: 'আমাদের ড্যাশবোর্ড দিয়ে, আপনি পেমেন্ট লিঙ্ক তৈরি করতে পারেন মাত্র কয়েক ক্লিকে। আপনার পেমেন্ট লিঙ্ক দিয়ে ক্রেতা পেমেন্ট করতে পারে অতি সহজে!'
        },
        storeDemo: {
            title: 'সামাজিক মাধ্যমে ব্যবসা করুন',
            content: 'সহজেই আপনার অনলাইন স্টোর শেয়ার করুন, হোয়াটসঅ্যাপ, ইনস্টাগ্রাম সহ সকল সামাজিক মাধ্যমে, গ্রাহক দ্রুত এবং নির্ভরযোগ্য পেমেন্ট করবে বিকাশ পেমেন্ট গেটওয়ের সহকারে'
        },
        reportDemo: {
            title: 'ব্যবসা বৃদ্ধি করুন',
            content: 'ড্যাশবোর্ডে লেনদেনের রিপোর্ট ব্যবহার করুন সহজেই আপনার পেমেন্ট, গ্রাহকদের এবং লেনদেন  ট্র্যাক করতে, যাতে আপনি ব্যবসা বৃদ্ধিতে মনোযোগ দিতে পারেন।' 
        },
        testimonial: {
            title: 'টেস্টিমোনিয়াল',
        },
        factsFig: {
            title: 'ফ্যাক্টস এবং ফিগার্স',
        },
        dashboard: 'ড্যাশবোর্ড',
        signup: 'সাইন আপ',
        promotionText1: 'রেফার করুন এবং জিতুন ৳১০০ বোনাস ',
        promotionText2: 'এখানে',
        blogHome: 'বিজনেস ব্লগ',
        paymentText1: 'বিকাশ পেমেন্ট লিংক',
        paymentText2: 'ব্যবহার করে',
        paymentText3: 'অনলাইনে পেমেন্ট নিন',
        signIn: 'সাইন ইন',
        refer: "রেফার করুন",
        bkashBusiness1: 'আপনার বিকাশ মার্চেন্ট একাউন্ট দিয়ে বিকাশ বিজনেস ড্যাশবোর্ড',
        bkashBusiness2: 'ব্যবহার করুন চার্জ ছাড়াই',
        merchantAccount: 'বিকাশ মার্চেন্ট একাউন্ট নেই ?',
        register: 'রেজিস্ট্রেশন করুন',
        paymentExperience: {
            t1: 'ঝামেলাহীন পেমেন্ট সুবিধা',
            t2: 'গ্রাহক',
            t3: 'ও',
            t4: 'মার্চেন্ট এর জন্য'
        },
        feature1: {
            t1: 'মেসেজিং প্ল্যাটফর্ম,',
            t2: 'এসএমএস',
            t3: 'বা ইমেইলের মাধ্যমে',
            t4: 'পেমেন্ট লিংক শেয়ার করুন',
        },
        feature2: {
            t1: 'গ্রাহকরা তাদের বিকাশ নাম্বার ব্যবহার করে',
            t2: 'যেকোনো ডিভাইস থেকে',
            t3: 'পেমেন্ট ',
            t4: 'করতে পারবেন',
        },
        feature3: {
            t1: 'মার্চেন্ট প্রতিটি সফল পেমেন্টের',
            t2: 'তথ্য ড্যাশবোর্ডে',
            t3: 'দেখতে পারবেন'
        },
        journey: 'বিকাশ বিজনেস ড্যাশবোর্ড',
        businessDashboard: 'বিজনেস ড্যাশবোর্ড',
        demo: {
            title1: 'কীভাবে শুরু করবেন',
            details1: 'ঘরে বসে অনায়াসে আপনার মার্চেন্ট একাউন্ট খুলুন এবং বিকাশ বিজনেস ড্যাশবোর্ড ব্যবহার করুন ',
            title2: 'মার্চেন্ট একাউন্ট খুলুন',
            details2: {
                t1: 'এই লিংকে গিয়ে আপনার জাতীয় পরিচয় পত্র, ছবি এবং ব্যবসায়িক পরিচয় দিয়ে',
                t2: 'আপনার মার্চেন্ট একাউন্ট খুলুন।'
            },
            title3: 'বিকাশ বিজনেস ড্যাশবোর্ডে সাইন আপ করুন',
            details3: {
                t1: 'এই',
                t2: 'লিংকে',
                t3: 'আপনার মার্চেন্ট একাউন্ট নাম্বার দিয়ে আপনার বিকাশ বিজনেস ড্যাশবোর্ডে সাইন আপ করুন'
            }
        },
        facebookGroup: {
            t1: 'আমাদের',
            t2: 'ফেসবুক',
            t3: 'গ্রুপে যোগ দিন',
            t4: 'সর্বশেষ খবর,  ফিচার এবং অফার সম্পর্কে জানতে'
        }
    },
    modal: {
        title: {
            t1: 'আপনার ব্যবসার',
            t2: ' ট্রেড লাইসেন্স',
            t3: 'কি আছে?',
        },
        confirm: {
            c1: 'হ্যাঁ',
            c2: '- মার্চেন্ট একাউন্ট খুলুন',
        },
        decline: {
            d1: 'না',
            d2: '- পার্সোনাল রিটেইল একাউন্ট  খুলুন',
        }
    }
};

export default bn;
