const TOKEN_EXPIRED = 'tokenExpired';
export default {
    evenBus: null,
    install(Vue) {
        this.evenBus = new Vue();
    },
    tokenExpired() {
        this.evenBus.$emit(TOKEN_EXPIRED)
    },
    onTokenRefreshFailure(handler) {
        this.evenBus.$on(TOKEN_EXPIRED, handler)
    },
}