const authModule = {
    state: {
        user: {
            name: '',
            phoneNumber: '',
        },
        validTokenTime: '',
        idToken: '',
        refreshToken: '',
        accessToken: '',
        logo: '',
        pageBasePath: '',
        prokashToken: '',
    },
    getters: {
        getValidTokenTime(state) {
           return state.validTokenTime
        },
        getIdToken(state) {
            return state.idToken;
        },
        getAccessToken(state) {
            return state.accessToken;
        },
        getPageBasePath(state) {
            return state.pageBasePath;
        },
        getRefreshToken(state) {
            return state.refreshToken;
        },
        getProkashToken(state) {
            return state.prokashToken;
        },
        getUserName(state) {
            return state.user.name;
        },
        getUserPhoneNumber(state) {
            return state.user.phoneNumber;
        },
        getPGWLogo(state) {
            return state.logo;
        },
        isAuthenticated(state) {
            return !!state.idToken;
        },
        getRefreshTokenPayload(state) {
            return {
                phoneNumber: state.user.phoneNumber,
                refreshToken: state.refreshToken
            };
        },
        hasProkashToken(state) {
            return !!state.prokashToken;
        }
    },
    mutations: {
        setCredentials(state, payload) {
            let now = new Date();
            let expiresInMs = payload.expiresIn * process.env.VUE_APP_ONE_SEC_IN_MS;
            let expiresIn = now.getTime() + expiresInMs;

            state.validTokenTime = expiresIn;
            state.user.name = payload.name || '';
            state.user.phoneNumber = payload.phoneNumber;
            state.idToken = payload.idToken;
            state.refreshToken = payload.refreshToken;
            state.accessToken = payload.accessToken;
            if(payload.prokashToken) { state.prokashToken = payload.prokashToken; }
        },
        setToken(state, payload) {
            payload.refreshToken = state.refreshToken;

            this.commit('setCredentials', payload);
        },
        logoutUser(state) {
            state.user.name = '';
            state.user.phoneNumber = '';
            state.idToken = '';
            state.refreshToken = '';
            state.validTokenTime = '';
            state.accessToken = '';
            state.prokashToken = '';
        },
        setPGWLogo(state, payload) {
            state.logo = "";
            state.logo = payload;
        },
        setPageBasePath(state, payload) {
          state.pageBasePath = payload;
        },
        setUserPhoneNumber(state ,number) {
            state.user.phoneNumber  = number;
        },
    },
};
export default authModule;
