import Vue from 'vue';
import Vuex from 'vuex';
import authModule from "@/store/module/auth";
import pageManagementModule from "@/store/module/pageManagement";
import transactionSummaryModule from "@/store/module/transactionSummary";
import blogModule from "@/store/module/blog";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const modules = {
    authModule,
    pageManagementModule,
    transactionSummaryModule,
    blogModule
};

export default new Vuex.Store({
    modules: modules,
    plugins: [createPersistedState({
        path: ['authModule'],
    })]
});
