const pageManagementModule = {
  state: {
    pageInfo: {
      packages: [{ name: "", price: "", description: "" }],
    },
    logoFile: {},
    isUpdate: false,
    customerInfoFields: [],
    paymentOption: "amount",
    promotionalBanner: "",
    merchantPromoBanner: [],
    currentSetupStep: "",
    link: {
      amount: "",
      description: "",
      urlPath: "",
    },
    fixedPaymentPageList: [],
    fixedPaymentPageListTokens: [""],
    confirmEmail: "",
    showLogoChangeDialog: false,
    paymentLink: "",
    releaseNote: {},
    customPage: {},
    isMerchantOnMerchantQuest: false,
    refund: {
      trxID: "",
    },
    isReferralActive: true,
    inbox: [],
    readNotices: [],
    readOffers: [],
  },
  getters: {
    getReleaseNote(state) {
      return state.releaseNote;
    },
    getPaymentLink(state) {
      return state.paymentLink;
    },
    getShowLogoChangeDialog(state) {
      return state.showLogoChangeDialog;
    },
    getFixedPaymentPageListTokens(state) {
      return state.fixedPaymentPageListTokens;
    },
    getFixedPaymentPageList(state) {
      return state.fixedPaymentPageList;
    },
    getLogoFile(state) {
      return state.logoFile;
    },
    getLink(state) {
      return state.link;
    },
    getPageInfoPropertyValue: (state) => (key) => {
      return state.pageInfo[key];
    },
    getPageInfo(state) {
      return state.pageInfo;
    },
    isUpdate(state) {
      return state.isUpdate;
    },
    getCustomerInfoFields(state) {
      return state.customerInfoFields;
    },
    getPaymentOption(state) {
      return state.paymentOption;
    },
    getPromotionalBanner(state) {
      return state.promotionalBanner;
    },
    getMerchantPromotionalBanner(state) {
      return state.merchantPromoBanner;
    },
    getCurrentSetupStep(state) {
      return state.currentSetupStep;
    },
    getConfirmEmail(state) {
      return state.confirmEmail;
    },
    getCustomPage(state) {
      return state.customPage;
    },
    getCustomInputFieldOptions() {
      return [
        {
          name: "name",
          label: "Your Name",
          placeholder: "Name",
          selected: false,
        },
        {
          name: "email",
          label: "Email Address",
          placeholder: "Email",
          selected: false,
        },
        {
          name: "mailingAddress",
          label: "Mailing Address",
          placeholder: "Address",
          selected: false,
        },
        {
          name: "reference",
          label: "Payment Reference",
          placeholder: "Reference",
          selected: false,
        },
        {
          name: "membershipID",
          label: "Membership ID",
          placeholder: "Membership Id",
          selected: false,
        },
        {
          name: "billMonth",
          label: "Month",
          placeholder: "Month",
          selected: false,
        },
      ];
    },
    getMerchantOnMerchantQuest(state) {
      return state.isMerchantOnMerchantQuest;
    },
    getRefund(state) {
      return state.refund;
    },
    getIsReferralActive(state) {
      return state.isReferralActive;
    },
    getInbox(state) {
      return state.inbox;
    },
  },
  mutations: {
    setReleaseNote(state, payload) {
      state.releaseNote = payload;
    },
    setPaymentLink(state, payload) {
      state.paymentLink = payload;
    },
    setShowLogoChangeDialog(state, payload) {
      state.showLogoChangeDialog = payload;
    },
    setFixedPaymentPageList(state, payload) {
      state.fixedPaymentPageList = payload;
    },
    setFixedPaymentPageListToken(state, payload) {
      state.fixedPaymentPageListTokens = payload;
    },
    setLink(state, payload) {
      state.link = payload;
    },
    clearPageInfo(state) {
      state.pageInfo = {
        packages: [{ name: "", price: "", description: "" }],
      };
      state.customerInfoFields = [];
      state.logoFile = {};
      state.paymentOption = "amount";
    },
    setPromotionalBanner(state, payload) {
      state.promotionalBanner = payload;
    },
    setMerchantPromoBanner(state, payload) {
      state.merchantPromoBanner = payload;
    },
    setUpdate(state) {
      state.isUpdate = !state.isUpdate;
    },
    setCustomerInfoFields(state, payload) {
      state.customerInfoFields = payload;
    },
    setUpdateFalse(state) {
      state.isUpdate = false;
    },
    setPageInfoProperty(state, { key, value }) {
      state.pageInfo[key] = value;
    },
    setPageInfo(state, payload) {
      state.pageInfo = {};
      state.pageInfo = payload;
    },
    addPageFields(state, payload) {
      state.pageInfo.fields = payload;
    },
    addPackage(state) {
      state.pageInfo.packages.push({ name: "", price: "", description: "" });
    },
    clearPackageList(state) {
      state.pageInfo.packages = [{ name: "", price: "", description: "" }];
    },
    deletePackage(state, position) {
      state.pageInfo.packages.splice(position, 1);
    },
    setPackageDetails(state, { position, key, value }) {
      let pageInfo = state.pageInfo;
      let pack = pageInfo.packages[position];

      pack[key] = value;
    },
    setLogoFile(state, payload) {
      state.logoFile = payload;
    },
    setPaymentOption(state, option) {
      state.paymentOption = option;
    },
    setCustomInputFieldChange(state, { customFieldName, key, value }) {
      let index = state.customerInfoFields.findIndex(
        (field) => field.name === customFieldName
      );

      if (index < 0) return;

      state.customerInfoFields[index][key] = value;
    },
    setCurrentSetupStep(state, payload) {
      state.currentSetupStep = payload;
    },
    setConfirmEmail(state, payload) {
      state.confirmEmail = payload;
    },
    setCustomPage(state, payload) {
      let page = payload;
      if (
        page.products &&
        page.products.filter((val) => val.isPopular).length > 0
      ) {
        page.productCategories = [
          { name: "Popular", productCategoryId: "1000" },
          ...page.productCategories,
        ];
      }
      state.customPage = page;
    },
    setMerchantOnMerchantQuest(state, payload) {
      state.isMerchantOnMerchantQuest = payload;
    },
    setRefund(state, payload) {
      state.refund = payload;
    },
    setIsReferralActive(state, payload) {
      state.isReferralActive = payload;
    },
    setInbox(state, payload) {
      state.inbox = payload;
    },
    clearPageState(state) {
      state.pageInfo = {
        packages: [{ name: "", price: "", description: "" }],
      };
      state.logoFile = {};
      state.isUpdate = false;
      state.customerInfoFields = [];
      state.paymentOption = "amount";
      state.promotionalBanner = "";
      state.merchantPromoBanner = [];
      state.currentSetupStep = "";
      state.link = {
        amount: "",
        description: "",
        urlPath: "",
      };
      state.fixedPaymentPageList = [];
      state.fixedPaymentPageListTokens = [""];
      state.confirmEmail = "";
      state.showLogoChangeDialog = false;
      state.paymentLink = "";
      state.releaseNote = {};
      state.customPage = {};
      state.isMerchantOnMerchantQuest = false;
      state.refund = {
        trxID: "",
      };
      state.isReferralActive = true;
      state.inbox = [];
      state.readNotices = [];
      state.readOffers = [];
    },
  },
  actions: {
    reInitiateAutoOnboarding(context, payload) {
      return this._vm.$cppClient.post("/single-webhook/configure", payload);
    },
    fetchNextFixedPaymentPageList(context, payload) {
      payload.status = "active";
      return this._vm.$cppClient
        .post("payment-link/list", payload)
        .then((res) => {
          let nextToken = res.data.nextToken;
          let fixedPaymentPageListTokens =
            context.getters.getFixedPaymentPageListTokens;
          fixedPaymentPageListTokens.push(nextToken);
          context.commit(
            "setFixedPaymentPageListToken",
            fixedPaymentPageListTokens
          );
          context.commit("setFixedPaymentPageList", res.data.result);
          context.commit("updatePageNum", "add");
        });
    },
    fetchPrevFixedPaymentPageList({ state, commit }, payload) {
      payload.status = "active";
      return this._vm.$cppClient
        .post("payment-link/list", payload)
        .then(({ data }) => {
          let fixedPaymentPageListTokens = state.fixedPaymentPageListTokens;
          fixedPaymentPageListTokens.pop();

          commit("setFixedPaymentPageListToken", fixedPaymentPageListTokens);
          commit("setFixedPaymentPageList", data.result);
          commit("updatePageNum", "sub");
        });
    },
  },
};
export default pageManagementModule;
