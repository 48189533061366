import TokenManager from "cpp-js-util/TokenManager";

const Axios = require('axios');
const {
    CLIENT_IP_HEADER,
    getIdAddress
} = require('cpp-js-util/CppUtil')

export default class CppRestClient {
    constructor(baseUrl, store, localStorage) {
        this.baseUrl = baseUrl
        this.store = store
        this.localStorage = localStorage;
        this.clientIpAddress = null;
        this.axios = Axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    async post(path, payload, captchaResponse, isMultiPart) {
        if (!this.clientIpAddress) {
            this.clientIpAddress = await getIdAddress();
        }

        let token
        try {
            token = await this.getToken(this.clientIpAddress);
        } catch (error) {
            console.debug(error)
            TokenManager.tokenExpired()
            throw error
        }

        let config = {
            headers: this.getCommonHeaders(this.clientIpAddress)
        }

        if (localStorage.getItem("visitorId")) config.headers['visitor-id'] = localStorage.getItem("visitorId");
        if (token) config.headers['Authorization'] = 'Bearer ' + token;
        if (captchaResponse) config.headers['captcha-response'] = captchaResponse;
        if (isMultiPart) config.headers['Content-Type'] = undefined;

        return this.axios.post(path, payload, config)
            .catch(e => this.handleError(e))
    }

    async postProkash(path, payload) {
        if (!this.clientIpAddress) {
            this.clientIpAddress = await getIdAddress();
        }

        let token
        try {
            token = this.store.getters.getProkashToken;
        } catch (error) {
            console.debug(error)
            throw error
        }

        let config = {
            headers: this.getCommonHeaders(this.clientIpAddress)
        }

        if (localStorage.getItem("visitorId")) config.headers['visitor-id'] = localStorage.getItem("visitorId");
        if (token) config.headers['Authorization'] = 'Bearer ' + token;

        console.log("prokash token ", token);

        return this.axios.post(path, payload, config)
            .catch(e => this.handleError(e))
    }
    getCommonHeaders(ipAddress) {
        let headers = {};

        headers[CLIENT_IP_HEADER] = ipAddress

        return headers
    }

    async download(path, payload) {
        if (!this.clientIpAddress) {
            this.clientIpAddress = await getIdAddress();
        }

        let token;
        try {
            token = await this.getToken(this.clientIpAddress);
        } catch (e) {
            console.debug(e.response)
            TokenManager.tokenExpired()
            return
        }

        if (!token) throw new Error('idToken is necessary')

        let headers = Object.assign({},
            {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            this.getCommonHeaders(this.clientIpAddress));

        let config = {
            responseType: 'arraybuffer',
            headers: headers
        };

        return this.axios.post(path, payload, config)
            .catch(err => this.handleError(err))
    }

    async downloadWithoutAuth(path, payload) {
        if (!this.clientIpAddress) {
            this.clientIpAddress = await getIdAddress();
        }
        let headers = Object.assign({},
            {
                'Content-Type': 'application/json'
            },
            this.getCommonHeaders(this.clientIpAddress));

        let config = {
            responseType: 'arraybuffer',
            headers: headers
        };

        return this.axios.post(path, payload, config)
            .catch(err => this.handleError(err))
    }

    async getToken(ipAddress) {
        let idToken = undefined;

        let hasValidToken = new Date().getTime() < this.store.getters.getValidTokenTime;

        if (hasValidToken) {
            idToken = this.store.getters.getIdToken;
        } else if (this.store.getters.getRefreshToken) {

            this.axios.defaults.headers = this.getCommonHeaders(ipAddress)

            await this.axios.post(this.baseUrl + '/refresh-token', this.store.getters.getRefreshTokenPayload)
                .then(({data}) => {
                    idToken = data.idToken;

                    this.store.commit('setToken', data);
                }).catch(err => {
                    throw err
                })
        }

        return idToken;
    }

    handleError(error) {
        if (error.response.status === 401) {
            TokenManager.tokenExpired()
        }

        throw error
    }
}
