const en = {
    menu: {
        home: 'Home'
    },
    setup: {
        title: 'Welcome to',
        title_slot: 'bKash Business Dashboard',
        sub_title: 'bKash Business Dashboard allows you to create payment links, track transactions and see important analytics so that you can focus more on growing your business',
        prerequisite: {
            title: 'What do you need?',
            wallet: 'bKash Merchant / Personal Retail Account',
            email: 'Active Email Account',
        },
        wallet_entry_form: {
            insert_wallet: 'Enter Your bKash Merchant Account Number',
            terms_conditions: 'Do you agree with our {conditions}?',
            terms_conditions_slot: 'terms and conditions',
            must_select: 'To proceed you must agree to terms & conditions',
            have_account: 'Already have an account?',
            sign_in: 'Sign in',
            wallet_mandatory: 'Wallet Number is required',
            wallet_length: 'Wallet Number must be 11 digit',
            wallet_valid: 'Enter a valid Wallet Number starting with 01',
            must_agree: 'Please agree to the terms & conditions to proceed',
        },
        otp_entry_form: {
            insert_otp: 'Enter OTP that has been sent to you',
            resend: 'Resend OTP?',
            otp_blank: "OTP is required",
            otp_length: 'OTP must be of 6 digits',
        },
        email_entry_form: {
            insert_mail: 'Enter Email Address',
        },
        confirm_email_entry_form: {
            insert_mail: 'Re-enter Email Address ',
        },
        verify_email: {
            verify_title: 'Verify your email address',
            sent: 'We have sent a verification link to your email address - {email-address}',
            use_link: 'Please use the link for verification.',
            resend: 'Resend Email',
            success_title_1: 'Thank you for registering for',
            success_title_2: 'bKash Business Dashboard',
            success_msg_1: 'You’ll receive a password setup link to sign in to the bKash Business Dashboard very soon over your email. Please set your password when signing in for the first time.',
            success_msg_2: 'Try again after 24 hours if don’t receive the email.',
        },
        email_length: "Must be of 4 characters atleast",
        email_valid: 'Email must be valid',
        email_mismatch: 'Email does not match',
        home_btn: 'Back to Homepage',
        proceed_btn: 'Proceed',
        coming_soon: {
            title: 'Coming soon!',
            sub: 'Registration feature to accept bKash Online Payment’ is currently under development. You will be able to register soon to accept bKash payments online. Click the link below to apply for registration right now.',
            btn: {
                register: 'Apply for registration',
                home: 'Back to home'
            }
        },
        copyright: 'Copyright © bKash 2021 Limited'
    },
    earnWithBkash: {
        title: 'Earn online with bKash',
        advertisementText:'Accept payments, send payouts, and manage business better',
        descriptionText:'Try bKash Business Dashboard for free by signing up with your bKash merchant account.',
        signup: 'Sign up Now',
        learnMore:'Learn More'
    },
    referral: {
        title: "By Referring bKash Business Dashboard",
        subTitle: "If someone uses your referral link to sign up and take payment, you will receive 100 Taka",
        subTitle2: "If your referred friend signs up on the dashboard and takes payment, you will receive 100 Taka ",
        earnAmount: "100 Taka",
        bonus: "bonus*",
        referralLink: "Referral Link",
        copyLink: "Copy Link",
        shareLink: "Share your referral link",
        referPersonally: "Write number to refer a person",
        referrerInputTitle: "Customer/Merchant/PRA Account",
        referrerInputLabel: "Enter your bKash number",
        referredInputTitle: "bKash Merchant/Non-bKash Number",
        referredInputLabel: "Enter number to refer",
        referredEmailCheckboxLabel: "Send Email to your friend",
        referredEmailInputLabel: "Enter your friend’s email address",
        referButtonLabel: "Refer",
        checkReferralLinkPart1: "Check your Referrals",
        checkReferralLinkPart2: "HERE",
        referSuccessMessage: "Your referral to merchant {referredWallet} is successful. Refer more merchants and win rewards!",
        descriptionTitle: "Win 100 tk by referring.",
        description: "To win, your friend needs to sign up and take a payment using payment link.",
        conditions: " Conditions applied",
        learnMore: "Learn more about bKash Business Dashboard ",
        learnMoreHere: "[Here]"
    },
    referralReport: {
        title: "Learn about your referrals",
        subTitle: "To check your referrals verify your bKash number to get the information in your given email address",
        referrerInputTitle: "Customer/Merchant/PRA Account",
        referrerInputLabel: "Enter your bKash number",
        sendOtpButtonLabel: "Send OTP",
        otpInputTitle: "OTP",
        otpInputLabel: "Enter OTP",
        emailInputTitle: "Email (to receive report)",
        emailInputLabel: "Enter your email address",
        sendEmailButtonLabel: "Send Email",
        resendOtpText: "You can request another OTP in {0} seconds.",
        otpSent: "We have sent you an OTP at {referrerWallet}.",
        reportSent: "You will receive your report at {referrerEmail}. Refer more merchants and win rewards!",
        wallet_entry_form: {
            wallet_mandatory: 'Wallet Number is required',
            wallet_length: 'Wallet Number must be 11 digit',
            wallet_valid: 'Enter a valid Wallet Number starting with 01',
        },
        email_length: "Must be of 4 characters at least",
        email_valid: 'Email must be valid',
        otp_entry_form: {
            otp_blank: "OTP is required",
            otp_length: 'OTP must be of 6 digits',
        }
    },
    landing: {
        registerInfo: {
            businessDashboard: 'Business Dashboard',
            createStore: 'Create a simple store with online payments in minutes',
            easySetup: 'Easy no-code setup. Perfect for selling products across social media.',
            getStarted: 'GET STARTED FREE',
            getBonus: 'Sign up and earn up-to ৳100',
            noAccount: 'No Merchant Account? No Problem',
            register: 'Register Now',
        },
        features: {
            paymentExperience: {
                t1: 'Seamless Payment Experience for both',
                t2: ' Customer',
                t3: ' &',
                t4: ' Merchant',
                t5: ''
            },
            pageManagement: {
                title: 'Payment Management',
                content: 'Create and share payment links to make your online business hassle-free.'
            },
            report: {
                title: 'Transaction & Report',
                content: 'See key performance metrics and transactions to learn about your business and customers.'
            },
            store: {
                title: 'Online Store',
                content: 'Create no-code online store and populate it with links, product and details about your business.'
            }
        },
        chatDemo: {
            title: 'Jump straight to selling',
            content: 'With our Dashboard, you can create and share your payment link in just a few clicks. Customer can use the payment link to make payment for your product or service. It’s that simple!'
        },
        storeDemo: {
            title: 'Selling powered by social',
            content: 'Easily share your online store on Instagram, WhatsApp, or anywhere else - providing customers quick and reliable transaction with bKash Payment Gateway.'
        },
        reportDemo: {
            title: 'Grow as you go',
            content: 'Use the transaction report in the dashboard to easily track your payments, customers and transaction. So that you can focus on growth.' 
        },
        testimonial: {
            title: 'Testimonial',
        },
        factsFig: {
            title: 'Facts & Figures',
        },
        dashboard: 'Dashboard',
        signup: 'Sign up',
        promotionText1: 'Refer & Earn ৳100 Bonus ',
        promotionText2: 'Here',
        blogHome: 'Business Blog',
        paymentText1: 'Receive Payments',
        paymentText2: 'Online using',
        paymentText3: 'bKash Payment Link',
        signIn: 'Sign in',
        refer: "Refer & earn",
        bkashBusiness1: 'Try bKash Business Dashboard for free by signing up',
        bkashBusiness2: 'with your bKash merchant account.',
        merchantAccount: 'Don’t have bKash Merchant Account?',
        register: 'Register Now',
        paymentExperience: {
            t1: 'Seamless Payment Experience for both',
            t2: 'Customer',
            t3: '&',
            t4: 'Merchant'
        },
        feature1: {
            t1: 'Share the',
            t2: 'Payment Link',
            t3: 'using messaging platforms,',
            t4: 'SMS or email',
        },
        feature2: {
            t1: 'Customer can make Payment',
            t2: 'from any device using',
            t3: 'bKash account',
            t4: 'number'
        },
        feature3: {
            t1: 'Merchants get updates of all',
            t2: 'successful Payments on the',
            t3: 'Dashboard'
        },
        journey: 'Start your journey with',
        businessDashboard: 'Business Dashboard',
        demo: {
            title1: 'How to get started',
            details1: 'Open merchant account and sign up for bKash Business Dashboard from the comfort of your home',
            title2: 'Open Merchant Account',
            details2: {
                t1: 'Use your personal and business information to open merchant account using this',
                t2: 'LINK'
            },
            title3: 'Sign up for Bkash Business Dashboard',
            details3: {
                t1: 'Use this',
                t2: 'LINK',
                t3: 'to sign up for your Business Dashboard using your bKash account number and email'
            }
        },
        facebookGroup: {
            t1: 'Join our',
            t2: 'Facebook',
            t3: 'group to get updated with',
            t4: 'latest news, features, and offers'
        }
    },

    modal: {
        title: {
            t1: 'Do you have',
            t2: 'Trade license',
            t3: 'for your business?',
        },
        confirm: {
            c1: 'Yes',
            c2: '- Apply for Merchant Account',
        },
        decline: {
            d1: 'No',
            d2: '- Apply for Personal Retail Account',
        }
    }
};

export default en;
