<template>
  <div id="app">
    <v-app style="height: 100%;">
      <Loader></Loader>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import './assets/styles/main.css';
@import './assets/styles/simplified.css';

#app {
  background-color: #F5F5F5;
}

.v-application a {
  color: transparent;
  text-decoration: none;
}
</style>