const blogModule = {
    state: {
        blogDetails: {},
        blogList: [],
    },
    getters: {
        getBlogDetails(state) {
            return state.blogDetails;
        }
    },
    mutations: {
        setBlogDetails(state, payload) {
            state.blogDetails = payload;
        }
    }
}
export default blogModule;
