var moment = require('moment');

export default {
    install(Vue) {
        Vue.filter('capitalize', function (value) {
            if (!value) return '';

            value = value.toString()

            return value.charAt(0).toUpperCase() + value.slice(1)
        })

        Vue.filter('dateTime', function (dateTime) {
            if (!dateTime) return;

            return moment(dateTime, 'YYYY-MM-DD hh:mm:ss.SSS')
                .format('DD/MM/YY hh:mm A');
        })

        Vue.filter('walletNumber', function (wallet) {
            if (!wallet) return;
            return wallet.replace('+880', '0');
        })

        Vue.filter('twoDecimalPlace', function (value) {
            if (!value) return
            return parseFloat(value).toFixed(2)
        })
    }
}


