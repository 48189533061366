const transactionSummaryModule = {
    state: {
        transactionHistory: {}
    },
    getters: {
        getCurrMonth(state) {
            return state.transactionHistory.currMonth;
        },
        getPrevMonth(state) {
            return state.transactionHistory.prevMonth;
        },
        getPrev2Month(state) {
            return state.transactionHistory.prev2Month;
        },
        getTransactionHistory(state)  {
            return state.transactionHistory;
        },
        getCurrentTime(state) {
            return state.transactionHistory.currentTime;
        },
        getDisclaimerMessage(state) {
            return state.transactionHistory.disclaimerMessage;
        },
    },
    mutations: {
        setTransactionHistory(state, payload) {
            // console.log("PL", payload)
            state.transactionHistory = payload;
        },
    },
};
export default transactionSummaryModule;
