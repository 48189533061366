const PaymentLinks = () => import("@/components/paymentLinks/PaymentLinks.vue");
const CreatePaymentLink = () => import("@/components/paymentLinks/CreatePaymentLink.vue")
const CreatePaymentLinkSuccess = () => import("@/components/paymentLinks/CreatePaymentLinkSuccess.vue")
const EditPaymentLinkSuccess = () => import("@/components/paymentLinks/EditPaymentLinkSuccess.vue")
const PaymentLinkDetailsPage = () => import("@/components/paymentLinks/PaymentLinkDetailsPage.vue")
const EditPaymentLink = () => import("@/components/paymentLinks/EditPaymentLink.vue")
const ConfirmSignUp = () => import("@/components/auth/ConfirmSignUp");
const SignIn = () => import("@/components/auth/SignIn");
const Dashboard = () => import("@/components/dashboard/Dashboard");
const TransactionReport = () => import("@/components/transactionReport/TransactionReport");
const ChangePassword = () => import("@/components/ChangePassword");
const AccountInfo = () => import("@/components/accountInfo/AccountInfo");
const Home = () => import("@/components/pageManagement/preview/home/Home");
const PaymentInfo = () => import("@/components/pageManagement/preview/paymentInfo/PaymentInfo");
const PreviewLayout = () => import("@/components/pageManagement/preview/PreviewLayout");
const PortalLayout = () => import("@/components/PortalLayout");
const ForgotPassword = () => import("@/components/forgotPassword/ForgotPassword");
const ResetPassword = () => import("@/components/auth/ResetPassword");
const SetPassword = () => import("@/components/auth/SetPassword");
const ErrorPage = () => import("cpp-js-util/components/common/ErrorPage");
const AcLimitFees = () => import("@/components/pdfViewer/AcLimitsFees");
const FaqSupport = () => import("@/components/pdfViewer/FaqAndSupport");
const Download = () => import("@/components/download/Download");
const DownloadStatement = () => import("@/components/download/pages/DownloadStatement");
const CampaignBannerDetails = () => import("@/components/pdfViewer/CampaignBannerDetails");
const SetupLayout = () => import("@/components/setup/ui/SetupLayout");
const LandingPage = () => import("@/components/landing/LandingLayout");
const EmailVerificationSuccess = () => import("../components/emailverificationSuccess/EmailVerificationSuccess");

//Disabled until getting confirmation from PO
// const Refund = () => import("@/components/refund/RefundLayout");
// const RefundForm = () => import("@/components/refund/RefundForm");
// const RefundConfirmationForm = () => import("@/components/refund/RefundConfirmationForm");
// const RefundSuccess = () => import("@/components/refund/RefundSuccess");

const BlogDetails = () => import("@/components/blog/BlogDetails");
const EarnWithDashboard = () => import("@/components/earnwithDashboard/EarnWithDashboard");
const ReferralCreate = () => import("@/components/referral/ReferralCreate");
const ReferralReport = () => import("@/components/referral/ReferralReport");
const ReferralLinkCreate = () => import("@/components/referral/ReferralLinkCreate");
const MerchantQuestPage = () => import("@/components/merchantQuest/MerchantQuestPage");
const PrivilegedOnboarding = () => import("@/components/privilegedOnboarding/PrivilegedOnboarding");
const CustomPage = () => import("@/components/pageManagement/customPage/CustomPage");
const StrSarReport = () => import("@/components/strSarReport/StrSarReport");

//Disabled until getting confirmation from PO
// const SendMoneyForm = () => import("@/components/sendMoney/SendMoneyForm.vue");
// const SendMoneyConfirmation = () => import("@/components/sendMoney/SendMoneyConfirmation.vue");
// const SendMoneySuccess = () => import("@/components/sendMoney/SendMoneySuccess.vue");
// const PaymentForm = () => import("@/components/merchantPayment/PaymentForm.vue");
// const PaymentConfirmation = () => import("@/components/merchantPayment/PaymentConfirmation.vue");
// const PaymentSuccess = () => import("@/components/merchantPayment/PaymentSuccessful.vue");
const OrderManagement = () => import("@/components/orderManagement/OrderManagement");
const OrderDetails = () => import("@/components/orderManagement/components/OrderDetails.vue")

export const routes = [
    {
        path: "/",
        component: PortalLayout,
        children: [
            {
                path: "/",
                name: "landing",
                component: LandingPage,
            },
            {
                path: "/privileged-onboarding",
                name: "privileged-onboarding",
                component: PrivilegedOnboarding,
            },
            {
                path: "/earn-with-dashboard",
                name: "earn-with-dashboard",
                component: EarnWithDashboard,
            },
            {
                path: '/info-page/:id',
                name: 'blog-details',
                component: BlogDetails,
            },
            {
                path: "/sign-up",
                name: "sign-up",
                component: SetupLayout,
            },
            {
                name: "dashboard",
                path: "/dashboard",
                component: Dashboard,
            },
            {
                name: "sign-in",
                path: "/sign-in",
                component: SignIn,
            },
            {
                name: "confirm-sign-up",
                path: "/confirm-sign-up",
                component: ConfirmSignUp,
            },
            {
                name: "reset-password",
                path: "/reset-password/:token",
                component: ResetPassword,
            },
            {
                name: "set-password",
                path: "/set-password/:token",
                component: SetPassword,
            },
            {
                name: "transaction-report",
                path: "/transaction-report",
                component: TransactionReport,
            },
            //Disabled until getting confirmation from PO
            // {
            //     name: "payment",
            //     path: "/payment",
            //     component: PaymentForm,
            // },
            // {
            //     name: "payment-confirm",
            //     path: "/payment/confirm",
            //     component: PaymentConfirmation,
            // },
            // {
            //     name: "payment-success",
            //     path: "/payment/success",
            //     component: PaymentSuccess,
            // },
            // {
            //     name: "send-money",
            //     path: "/send-money",
            //     component: SendMoneyForm,
            // },
            // {
            //     name: "send-money-confirm",
            //     path: "/send-money/confirm",
            //     component: SendMoneyConfirmation,
            // },
            // {
            //     name: "send-money-success",
            //     path: "/send-money/success",
            //     component: SendMoneySuccess,
            // },
            {
                name: "change-password",
                path: "/change-password",
                component: ChangePassword,
            },
            {
                name: "account-info",
                path: "/account-info",
                component: AccountInfo,
            },
            {
                name: "forgot-password",
                path: "/forgot-password",
                component: ForgotPassword,
            },
            {
                name: "merchant-quest",
                path: "/merchant-quest",
                component: MerchantQuestPage,
            },
            {
                name: "referandearn",
                path: "/referandearn",
                redirect: {name: "refer"},
            },
            {
                name: "refer",
                path: "/refer",
                component: ReferralLinkCreate,
            },
            {
                name: "refer-form",
                path: "/refer-form",
                component: ReferralCreate,
            },
            {
                name: "referralreport",
                path: "/referralreport",
                component: ReferralReport,
            },

            {
                name: "ac-limits-fees",
                path: "/ac-limits-fees",
                component: AcLimitFees,
            },
            {
                name: "faq-support",
                path: "/faq-support",
                component: FaqSupport,
            },
            {
                name: "campaign-banner-details",
                path: "/campaign-banner-details",
                component: CampaignBannerDetails,
            },
            {
                name: "email-verification-success",
                path: "/verify/email",
                component: EmailVerificationSuccess,
            },
            //Disabled until getting confirmation from PO
            // {
            //     name: 'refund',
            //     path: '/refund',
            //     component: Refund,
            // },
            // {
            //     name: "refund-payment",
            //     path: "/refund-payment/initiate",
            //     component: RefundForm,
            // },
            // {
            //     name: "refund-confirmation",
            //     path: "/refund-payment/confirm",
            //     component: RefundConfirmationForm,
            // },
            // {
            //     name: "refund-success",
            //     path: "/refund-payment/success",
            //     component: RefundSuccess,
            // },
            {
                name: "str-sar-report",
                path: "/strsarform",
                component: StrSarReport,
            },
            {
                name: "payment-links",
                path: "/payment-links",
                component: PaymentLinks
            },
            {
                name: "create-payment-link",
                path: "/payment-links/create",
                component: CreatePaymentLink
            },
            {
                name: "edit-payment-link",
                path: "/payment-links/edit/:id",
                component: EditPaymentLink
            },
            {
                name: "create-payment-link-success",
                path: "/payment-links/create/success/:id",
                component: CreatePaymentLinkSuccess
            },
            {
                name: "edit-payment-link-success",
                path: "/payment-links/edit/success/:id",
                component: EditPaymentLinkSuccess
            },
            {
                name: "payment-link-details-page",
                path: "/payment-links/details/:id",
                component: PaymentLinkDetailsPage
            },
            {
                name: "custom-page",
                path: "/payment-links/custom-page",
                component: CustomPage,
            },
            {
                name: "order-management",
                path: "/order-management",
                component : OrderManagement,
            },
            {
                name: "order-management-details",
                path: "/order-management/details/:orderId",
                component: OrderDetails,
                props: true
            },
        ],
    },
    {
        name: "invoice",
        path: "/order-management/invoice/:orderId",
        component: ()=>import("@/components/orderManagement/common/Invoice.vue"),
        props: true
    },
    {
        path: "/preview",
        component: PreviewLayout,
        children: [
            {
                path: "home-page",
                name: "customer-home-page",
                component: Home,
            },
            {
                path: "payment-page",
                name: "customer-payment-page",
                component: PaymentInfo,
            },
        ],
    },
    {
        path: "/download",
        component: Download,
        children: [
            {
                path: "statement",
                name: "statement",
                component: DownloadStatement,
            },
        ],
    },
    {
        path: "*",
        name: "error",
        component: ErrorPage,
        props: true,
    },
];
